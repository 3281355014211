.gene-logo {
  height: 18px;
  width: 127px;
  background-image: url('../../images/logo_Genentech@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gene-logo-header {
  height: 31px;
  width: 120px;
  background-image: url('../../images/svg/genentech-logo-withreche-member.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.icon {
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 8px;
  background-color: transparent;
  &__insights {
    background-image: url('../../images/icon_sidebar_insightBoards.svg');
    width: 18px;
    height: 16px;
  }
  &__favorite {
    background-image: url('../../images/icon_sidebar_favoriteDashboard.svg');
    width: 14px;
    height: 18px;
  }
  &__edit {
    background-image: url('../../images/icon_sidebar_edit.svg');
    width: 18px;
    height: 16px;
  }
  &__edit_black {
    background-image: url('../../images/svg/icon_edit_black.svg');
    width: 22.5px;
    height: 20px;
  }
  &__user_avatar {
    background-image: url('../../images/svg/icon_avatar_outline.svg');
    width: 24px;
    height: 24px;
  }
  &__edit-metric {
    background-image: url('../../images/svg/icon_edit.svg');
    width: 14px;
    height: 12px;
    margin-right: 0;
  }
  &__share {
    background-image: url('../../images/svg/icon_share.svg');
    width: 16px;
    height: 13px;
  }
  &__share-popup {
    background-image: url('../../images/svg/icon_share-popup.svg');
    width: 19px;
    height: 13px;
  }
  &__copy-insight {
    background-image: url('../../images/svg/icon_copy.svg');
    width: 14px;
    height: 16px;
    margin-right: 0;
  }
  &__duplicate {
    background-image: url('../../images/svg/icon_copy.svg');
    width: 17px;
    height: 18px;
    margin-right: 0;
  }
  &__copy-insight-popup {
    background-image: url('../../images/svg/icon_copy-popup.svg');
    width: 14px;
    height: 22px;
    margin-right: 0;
  }
  &__expand_view {
    background-image: url('../../images/svg/icon_expandView_alternate.svg');
    width: 12px;
    height: 12px;
    margin-right: 0;
  }
  &__delete {
    background-image: url('../../images/svg/icon_delete.svg');
    width: 10px;
    height: 12px;
    margin-right: 7px;
  }
  &__e-mail {
    background-image: url('../../images/svg/icon_email.svg');
    width: 16px;
    height: 12px;
    margin-right: 7px;
  }
  &__info_icon-yellow {
    background-image: url('../../images/svg/icon_info_yellow.svg');
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 6px;
    margin-bottom: -3px;
  }
  &__delete-insight {
    background-image: url('../../images/svg/icon_delete.svg');
    width: 12px;
    height: 14px;
    margin-right: 7px;
  }
  &__delete-tile {
    background-image: url('../../images/svg/icon_delete.svg');
    width: 16px;
    height: 18px;
    margin-right: 7px;
  }
  &__add-big {
    background-image: url('../../images/svg/icon_customerHierarchy_addTitle.svg');
    width: 18px;
    height: 18px;
  }
  &__back {
    background-image: url('../../images/svg/icon_back.svg');
    width: 12px;
    height: 12px;
  }
  &__configureTitle {
    background-image: url('../../images/svg/icon_configureTitle.svg');
    width: 19px;
    height: 20px;
  }
  &__chartView {
    background-image: url('../../images/svg/icon_chartView.svg');
    width: 11px;
    height: 11px;
  }
  &__chartView--active {
    background-image: url('../../images/svg/icon_chartView_active.svg');
    width: 11px;
    height: 11px;
  }
  &__tableView {
    background-image: url('../../images/svg/icon_tableView.svg');
    width: 13px;
    height: 11px;
  }
  &__tableView--active {
    background-image: url('../../images/svg/icon_tableView_active.svg');
    width: 13px;
    height: 11px;
  }
  &__nav-user-dropdown {
    background-image: url('../../images/svg/icon_menu_dropdown.svg');
    width: 10px;
    height: 6px;
    margin-right: 0;
    display: inline-block;
    margin-left: 10px;
  }
  &__home {
    background-image: url('../../images/svg/icon_menu_home.svg');
    width: 18px;
    height: 14px;
  }
  &__customer-view {
    background-image: url('../../images/svg/icon_menu_customerView.svg');
    width: 18px;
    height: 16px;
  }
  &__customer-view--title {
    background-image: url('../../images/svg/icon_customerViewTitle.svg');
    width: 18px;
    height: 16px;
  }
  &__insight-board-title {
    background-image: url('../../images/svg/icon_insightBoardTitle.svg');
    width: 18px;
    height: 16px;
  }
  &__hashtag {
    background-image: url('../../images/svg/hashtag.svg');
    width: 11px;
    height: 11px;
  }
  &__hashtag--active {
    background-image: url('../../images/svg/icon_hashtag_active.svg');
    width: 13px;
    height: 11px;
  }
  &__businessPerformance {
    background-image: url('../../images/svg/icon_menu_businessPerformance.svg');
    width: 10px;
    height: 18px;
  }
  &__ce-calendar {
    background-image: url('../../images/svg/icon_menu_calendar.svg');
    width: 15px;
    height: 17px;
  }
  &__contracting {
    background-image: url('../../images/svg/icon_menu_contracting.svg');
    width: 18px;
    height: 16px;
  }
  &__market-landscape {
    background-image: url('../../images/svg/icon_menu_marketLandscape.svg');
    width: 18px;
    height: 14px;
  }
  &__medical {
    background-image: url('../../images/svg/icon_menu_medical-v1.svg');
    width: 14px;
    height: 18px;
  }
  &__payer {
    background-image: url('../../images/svg/icon_menu_payer.svg');
    width: 19px;
    height: 13px;
  }
  &__access {
    background-image: url('../../images/svg/icon_menu_access.svg');
    width: 14px;
    height: 16px;
  }
  &__engagement {
    background-image: url('../../images/svg/icon_menu_engagement.svg');
    width: 20px;
    height: 14px;
  }
  &__my-view-documents-active {
    background-image: url('../../images/svg/icon_documentTitle_active.svg');
    width: 17px;
    height: 22px;
  }
  &__my-view-documents {
    background-image: url('../../images/svg/icon_documentTitle.svg');
    width: 17px;
    height: 22px;
  }
  &__my-view {
    background-image: url('../../images/svg/icon_myView.svg');
    width: 27px;
    height: 22px;
  }
  &__my-view-active {
    background-image: url('../../images/svg/icon_myView_active.svg');
    width: 27px;
    height: 22px;
  }
  &__my-default {
    background-image: url('../../images/svg/icon_myViewTitle.svg');
    width: 23px;
    height: 23px;
  }
  &__my-default-active {
    background-image: url('../../images/svg/icon_myViewTitle_active.svg');
    width: 23px;
    height: 23px;
  }
  &__configure {
    background-image: url('../../images/svg/icon_configure.svg');
    width: 15px;
    height: 16px;
  }
  &__configure-active {
    background-image: url('../../images/svg/icon_configure-active.svg');
    width: 15px;
    height: 16px;
  }
  &__configure-advance {
    background-image: url('../../images/svg/icon_configure-advance.svg');
    width: 13px;
    height: 14px;
  }
  &__configure-advance-active {
    background-image: url('../../images/svg/icon_configure-advance-active.svg');
    width: 13px;
    height: 14px;
  }
  &__configure-check {
    background-image: url('../../images/svg/icon_configureCheck.svg');
    width: 16px;
    height: 12px;
  }
  &__configure-checkbackground {
    background-image: url('../../images/svg/icon_configureCheckBackground.svg');
    width: 35px;
    height: 35px;
    margin-top: 2px;
  }
  &__configure-checkbackground-account-plan {
    background-image: url('../../images/svg/icon_configureCheckBackground.svg');
    width: 32px;
    height: 32px;
  }
  &__configure-checkbackground-account-plan-disabled {
    background-image: url('../../images/svg/icon_configureCheckBackgroundDisabled.svg');
    width: 32px;
    height: 32px;
  }
  &__title-warning {
    background-image: url('../../images/svg/icon_warning.svg');
    width: 16px;
    height: 14px;
  }
  &__eco-align-change-warning {
    background-image: url('../../images/svg/icon_warning.svg');
    width: 30px;
    height: 25px;
  }
  &__eco-align-change-warning-alerts {
    background-image: url('../../images/svg/icon_warning.svg');
    width: 23.04px;
    height: 20px;
  }
  &__configureEdit {
    background-image: url('../../images/svg/icon_configureEdit.svg');
    width: 15px;
    height: 15px;
  }
  &__configureEditTile {
    background-image: url('../../images/svg/icon_configureEdit.svg');
    width: 12px;
    height: 12px;
  }
  &__configureEditInsightTile {
    background-image: url('../../images/svg/icon_configureEdit.svg');
    width: 14px;
    height: 14px;
  }
  &__configureEditInsightTitle {
    background-image: url('../../images/svg/icon_configureEditInsightTitle.svg');
    width: 16px;
    height: 16px;
  }
  &__add {
    background-image: url('../../images/svg/icon_add.svg');
    width: 12px;
    height: 12px;
  }
  &__add_default {
    background-image: url('../../images/svg/icon_add.svg');
    width: 14px;
    height: 14px;
  }
  &__close {
    background-image: url('../../images/svg/icon_close.svg');
    width: 14px;
    height: 14px;
  }
  &__search {
    background-image: url('../../images/svg/icon_search.svg');
    width: 15px;
    height: 16px;
  }
  &__search_gray {
    background-image: url('../../images/svg/icon_search.svg');
    width: 10.9px;
    height: 11px;
  }
  &__advance-search {
    background-image: url('../../images/svg/icon_advance-search.svg');
    width: 10.9px;
    height: 11px;
  }
  &__advance-search-white {
    background-image: url('../../images/svg/icon_advance-search-white.svg');
    width: 10.9px;
    height: 11px;
  }

  &__trends {
    background-image: url('../../images/svg/icon_trendsTitle_white.svg');
    width: 18px;
    height: 14px;
    color: white;
  }
  &__trends--black {
    background-image: url('../../images/svg/icon_trendsTitle.svg');
    width: 27px;
    height: 20px;
    color: white;
  }
  &__arrowDown {
    background-image: url('../../images/svg/icon_arrowDown.svg');
    width: 20px;
    height: 24px;
  }
  &__view-more {
    background-image: url('../../images/svg/icon_viewMore.svg');
    width: 13px;
    height: 8px;
    color: white;
  }
  &__arrow-down {
    background-image: url('../../images/svg/icon_arrowDown.svg');
    width: 8px;
    height: 9px;
  }
  &__arrow-up {
    background-image: url('../../images/svg/icon_arrowUp.svg');
    width: 8px;
    height: 9px;
  }
  &__external {
    background-image: url('../../images/svg/icon_externalLink.svg');
    width: 12px;
    height: 12px;
  }
  &__external-blue {
    background-image: url('../../images/svg/icon_externalLink_blue.svg');
    width: 12px;
    height: 12px;
  }
  &__trends-insight {
    background-image: url('../../images/svg/icon_trendsInsights.svg');
    width: 21px;
    height: 21px;
  }
  &__card-toggle {
    background-image: url('../../images/svg/icon_more.svg');
    width: 17px;
    height: 5px;
    background-size: auto;
    margin-left: 5px;
  }
  &__negative {
    background-image: url('../../images/svg/icon_indicatorNegative.svg');
    width: 18px;
    height: 12px;
  }
  &__positive {
    background-image: url('../../images/svg/icon_indicatorPositive.svg');
    width: 18px;
    height: 12px;
  }
  &__view-more {
    background-image: url('../../images/svg/icon_viewMore.svg');
    width: 13px;
    height: 8px;
    color: white;
  }
  &__avatar {
    background-image: url('../../images/svg/icon_avatar.svg');
    width: 20px;
    height: 20px;
  }
  &__other-tools {
    background-image: url('../../images/svg/icon_menu_tools.svg');
    width: 16px;
    height: 16px;
  }
  &__help {
    background-image: url('../../images/svg/icon_help.svg');
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }

  &__affiliations_nested_view {
    background-image: url('../../images/svg/icon_affiliations_pivotTable.svg');
    width: 26px;
    height: 22px;
    margin-right: 0;
  }

  &__affiliations_nested_view-active {
    background-image: url('../../images/svg/icon_affiliations_pivotTable_active.svg');
    width: 26px;
    height: 22px;
    margin-right: 0;
  }
  &__affiliations_standard_view {
    background-image: url('../../images/svg/icon_affiliations_standardTable.svg');
    width: 26px;
    height: 22px;
    margin-right: 0;
  }

  &__affiliations_standard_view-active {
    background-image: url('../../images/svg/icon_affiliations_standardTable_active.svg');
    width: 26px;
    height: 22px;
    margin-right: 0;
  }

  &__affiliations_standardTable_settings {
    background-image: url('../../images/svg/icon_affiliations_standardTable_settings.svg');
    cursor: pointer;
    width: 29px;
    height: 17px;
    margin-right: 0;
  }

  &__affiliations_standardTable_excel {
    background-image: url('../../images/svg/icon_affiliations_standardTable_excel.svg');
    cursor: pointer;
    width: 26px;
    height: 17px;
    margin-right: 0;
  }
  &__affiliations_export_excel {
    background-image: url('../../images/svg/icon_affiliations_export_excel.svg');
    cursor: pointer;
    width: 30px;
    height: 20px;
    margin-right: 0;
  }
  &__affiliations_export_excel1 {
    background-image: url('../../images/svg/icon_affiliations_export_excel1.svg');
    cursor: pointer;
    width: 30px;
    height: 20px;
    margin-right: 0;
  }

  &__affiliations_pivotTable_expand {
    background-image: url('../../images/svg/icon_affiliations_pivotTable_expand.svg');
    width: 16px;
    height: 16px;
    margin-right: 0;
  }

  &__affiliations_pivotTable_collapse {
    background-image: url('../../images/svg/icon_affiliations_pivotTable_collapse.svg');
    width: 14px;
    height: 4px;
    margin-right: 0;
  }
  &__nested_expand {
    background-image: url('../../images/svg/icon_customerHierarchy_expandNested.svg');
    width: 14px;
    height: 14px;
    margin-right: 0;
  }
  &__nested_collapse {
    background-image: url('../../images/svg/icon_customerHierarchy_collapseNested.svg');
    width: 14px;
    height: 3px;
    margin-right: 0;
  }
  &__comments-title {
    background-image: url('../../images/svg/icon_customerHierarchy_commentTitle.svg');
    width: 21px;
    height: 18px;
    margin-right: 2px;
  }
  &__email {
    background-image: url('../../images/svg/icon_touchpoint_email.svg');
    width: 15px;
    height: 11px;
  }
  &__house-email {
    background-image: url('../../images/svg/icon_touchpoint_houseEmail.svg');
    width: 15px;
    height: 15px;
  }
  &__phone {
    background-image: url('../../images/svg/icon_touchpoint_phone.svg');
    width: 14px;
    height: 14px;
  }
  &__in-person {
    background-image: url('../../images/svg/icon_touchpoint_people.svg');
    width: 18px;
    height: 13px;
  }
  &__enrollment {
    background-image: url('../../images/svg/icon_touchpoint_enrollment.svg');
    width: 11px;
    height: 15px;
  }
  &__sent {
    background-image: url('../../images/svg/icon_touchpoint_send.svg');
    width: 14px;
    height: 14px;
  }
  &__response {
    background-image: url('../../images/svg/icon_touchpoint_survey.svg');
    width: 11px;
    height: 14px;
  }
  &__medical-inquiry {
    background-image: url('../../images/svg/icon_touchpoint_medicalInquiry.svg');
    width: 11px;
    height: 15px;
  }
  &__video {
    background-image: url('../../images/svg/icon_touchpoint_video.svg');
    width: 16px;
    height: 11px;
  }
  &__enrollment {
    background-image: url('../../images/svg/icon_touchpoint_enrollmentAccess.svg');
    width: 11px;
    height: 15px;
  }
  &__pact {
    background-image: url('../../images/svg/icon_touchpoint_enrollment.svg');
    width: 11px;
    height: 15px;
  }
  &__prp-enotice {
    background-image: url('../../images/svg/icon_contractEmail.svg');
    width: 15px;
    height: 15px;
  }
  &__message {
    background-image: url('../../images/svg/icon_touchpoint_message.svg');
    width: 14px;
    height: 12px;
  }
  &__vae {
    background-image: url('../../images/svg/icon_touchpoint_vae.svg');
    width: 15px;
    height: 15px;
  }
  &__chevron-right {
    background-image: url('../../images/svg/chevronright.svg');
    width: 6px;
    height: 10px;
  }
  &__chevron-left-blue {
    background-image: url('../../images/svg/chevronleft.svg');
    width: 8px;
    height: 14px;
    align-self: center;
    transform: rotate(180deg);
    margin-right: -10px;
    margin-left: 10px;
    cursor: pointer;
  }
  &__chevron-right-blue {
    background-image: url('../../images/svg/chevronleft.svg');
    width: 8px;
    height: 14px;
    align-self: center;
    margin-left: -10px;
    margin-right: 10px;
    cursor: pointer;
  }
  &__view {
    background-image: url('../../images/svg/icon_view.svg');
    width: 20px;
    height: 13px;
  }
  &__download {
    background-image: url('../../images/svg/icon_download.svg');
    width: 18px;
    height: 18px;
  }
  &__file-download {
    background-image: url('../../images/svg/file-arrow-down-solid.svg');
    width: 14px;
    height: 20px;
  }
  &__export-svg {
    background-image: url('../../images/svg/file-image-regular.svg');
    width: 15px;
    height: 20px;
  }
  &__darwinReportsTitle {
    background-image: url('../../images/svg/icon_darwinReportsTitle.svg');
    width: 12px;
    height: 16px;
  }
  &__addTitle {
    background-image: url('../../images/svg/icon_addTitle.svg');
    width: 16px;
    height: 16px;
  }
  &__link-title {
    background-image: url('../../images/svg/icon_linkTitle.svg');
    width: 24px;
    height: 19px;
  }
  &__darwinReports {
    background-image: url('../../images/svg/icon_darwinReports.svg');
    width: 15px;
    height: 20px;
  }
  &__calendar {
    background-image: url('../../images/svg/event.svg');
    width: 15px;
    height: 16px;
  }
  &__user {
    background-image: url('../../images/svg/icon_user-solid.svg');
    width: 20px;
    height: 20px;
  }
  &__sharedby {
    background-image: url('../../images/svg/icon_sharedSite.svg');
    width: 20px;
    height: 14px;
  }
  &__no-data {
    background-image: url('../../images/png/icon_no-data.png');
    width: 85px;
    height: 93px;
  }
  &__logo {
    background-image: url('../../images/360-logo.png');
    width: 176px;
    height: 199px;
  }
  &__chevron-down {
    background-image: url('../../images/svg/caret-down-solid.svg');
    width: 10px;
    height: 12px;
    margin: 4px;
    margin-left: 8px;
    margin-top: 0;
  }
  &__chevron-down-white {
    background-image: url('../../images/svg/caret-down-solid-white.svg');
    width: 10px;
    height: 12px;
    margin-left: 6px;
    margin-top: 3px;
  }
  &__chevron-up {
    background-image: url('../../images/svg/caret-up-solid.svg');
    width: 10px;
    height: 12px;
    margin: 4px;
    margin-left: 8px;
    margin-top: 0px;
  }
  &__drag {
    background-image: url('../../images/svg/icon_dragIndicator.svg');
    width: 10px;
    height: 15px;
    margin-right: 10px;
  }
  &__menu-collapse {
    background-image: url('../../images/svg/icon_menu_collapse.svg');
    width: 10px;
    height: 6px;
  }
  &__menu-expand {
    background-image: url('../../images/svg/icon_menu_expand.svg');
    width: 10px;
    height: 6px;
  }
  &__kpi-expand {
    background-image: url('../../images/svg/icon_kpi_expand.svg');
    width: 10px;
    height: 6px;
  }
  &__kpi-collapse {
    background-image: url('../../images/svg/icon_kpi_collapse.svg');
    width: 10px;
    height: 6px;
  }
  &__feedback {
    background-image: url('../../images/svg/icon_feedback.svg');
    width: 18px;
    height: 16px;
  }
  &__feedback-black {
    background-image: url('../../images/svg/icon_feedbackForm.svg');
    width: 23px;
    height: 20px;
  }
  &__info {
    background-image: url('../../images/svg/icon_info.svg');
    width: 13px;
    height: 13px;
    border-radius: 6px;
  }
  &__info-icon-yellow {
    background-image: url('../../images/svg/icon_info_yellow.svg');
    width: 44px;
    height: 18px;
    border-radius: 6px;
  }
  &__add-blue {
    background-image: url('../../images/svg/icon_add_blue.svg');
    width: 14px;
    height: 14px;
    border-radius: 6px;
  }
  &__add-black {
    background-image: url('../../images/svg/icon_add_black.svg');
    width: 14px;
    height: 14px;
    border-radius: 6px;
  }
  &__link_icon {
    background-image: url('../../images/svg/Icon-fa-solid-link.svg');
    width: 18px;
    height: 14px;
    border-radius: 6px;
  }

  &__info-big {
    background-image: url('../../images/svg/circle-info-solid.svg');
    width: 18px;
    height: 18px;
  }
  &__info__gray {
    background-image: url('../../images/svg/icon_info_gray.svg');
    width: 13px;
    height: 13px;
    border-radius: 6px;
    cursor: pointer;
  }
  &__userGuide {
    background-image: url('../../images/svg/icon_userGuide.svg');
    width: 16px;
    height: 16px;
  }
  &__customer-hierarchy {
    background-image: url('../../images/svg/icon_menu_hierarchy.svg');
    width: 20px;
    height: 16px;
  }
  &__customer-hierarchy-view {
    background-image: url('../../images/svg/icon_hierarchy.svg');
    width: 25px;
    height: 20px;
  }
  &__org-save {
    background-image: url('../../images/svg/icon_customerHierarchy_save.svg');
    width: 14px;
    height: 14px;
  }
  &__org-save-disabled {
    background-image: url('../../images/svg/icon_customerHierarchy_save_disabled.svg');
    width: 14px;
    height: 14px;
  }
  &__org-saved-view {
    background-image: url('../../images/svg/icon_customerHierarchy_savedView.svg');
    width: 18px;
    height: 12px;
  }
  &__org-saved-view-disabled {
    background-image: url('../../images/svg/icon_customerHierarchy_savedView_disabled.svg');
    width: 18px;
    height: 12px;
  }
  &__reset {
    background-image: url('../../images/svg/icon_customerHierarchy_reset.svg');
    width: 13px;
    height: 13px;
  }
  &__reset-disabled {
    background-image: url('../../images/svg/icon_customerHierarchy_reset-disabled.svg');
    width: 13px;
    height: 13px;
  }
  &__reset-disabled-account-plan {
    background-image: url('../../images/svg/icon_customerHierarchy_reset-disabled.svg');
    width: 14px;
    height: 14px;
  }
  &__dropdown {
    background-image: url('../../images/svg/icon_customerHierarchy_dropdown.svg');
    width: 11px;
    height: 6px;
  }
  &__tooltip {
    background-image: url('../../images/svg/icon_customerHierarchy_tooltip.svg');
    width: 12px;
    height: 12px;
  }
  &__comments {
    background-image: url('../../images/svg/icon_customerHierarchy_menu_comments.svg');
    width: 21px;
    height: 18px;
    margin-right: 0;
  }
  &__comments-action {
    background-image: url('../../images/svg/icon_customerHierarchy_menu_comments.svg');
    width: 15px;
    height: 13px;
  }
  &__add-sites {
    background-image: url('../../images/svg/icon_customerHierarchy_expand.svg');
    width: 14px;
    height: 14px;
  }
  &__move {
    background-image: url('../../images/svg/icon_customerHierarchy_move.svg');
    width: 14px;
    height: 14px;
  }
  &__remove {
    background-image: url('../../images/svg/icon_customerHierarchy_menu_removeSites.svg');
    width: 12px;
    height: 14px;
  }
  &__assign-labels {
    background-image: url('../../images/svg/icon_customerHierarchy_menu_assignLabels.svg');
    width: 14px;
    height: 14px;
  }
  &__shared {
    background-image: url('../../images/svg/icon_customerHierarchy_sharedSite.svg');
    width: 14px;
    height: 11px;
    margin-right: 5px;
  }
  &__manage-labels {
    background-image: url('../../images/svg/icon_customerHierarchy_menu_manageLabels.svg');
    width: 18px;
    height: 14px;
  }
  &__assignLabels-black {
    background-image: url('../../images/svg/icon_customerHierarchy_assignLabels.svg');
    width: 14px;
    height: 14px;
  }
  &__edit-attributes {
    background-image: url('../../images/svg/icon_customerHierarchy_menu_assignAttributes.svg');
    width: 17px;
    height: 14px;
  }
  &__add-comments {
    background-image: url('../../images/svg/icon_customerHierarchy_addComment.svg');
    width: 15px;
    height: 13px;
  }
  &__pdf {
    background-image: url('../../images/svg/icon_customerHierarchy_pdf.svg');
    width: 14px;
    height: 18px;
  }
  &__expand-all {
    background-image: url('../../images/svg/square-plus-regular.svg');
    width: 17px;
    height: 18px;
  }
  &__collapse-all {
    background-image: url('../../images/svg/square-minus-regular.svg');
    width: 17px;
    height: 18px;
  }
  &__menuComments {
    background-image: url('../../images/svg/icon_customerHierarchy_menu_comments.svg');
    width: 25px;
    height: 20px;
  }
  &__zoom-in {
    background-image: url('../../images/svg/icon_customerHierarchy_zoomIn.svg');
    width: 18px;
    height: 18px;
  }
  &__zoom-out {
    background-image: url('../../images/svg/icon_customerHierarchy_zoomOut.svg');
    width: 18px;
    height: 18px;
  }
  &__filter {
    background-image: url('../../images/svg/icon_customerHierarchy_filter.svg');
    width: 14px;
    height: 14px;
  }
  &__filter-active {
    background-image: url('../../images/svg/icon_customerHierarchy_filter_active.svg');
    width: 14px;
    height: 14px;
  }
  &__veeva {
    background-image: url('../../images/svg/veeva.svg');
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
  &__primarycontact {
    background-image: url('../../images/svg/primaryContact.svg');
    width: 12px;
    height: 9px;
    margin-left: 5px;
  }
  &__white_primarycontact {
    background-image: url('../../images/svg/whitePrimaryContact.svg');
    width: 12px;
    height: 9px;
    margin-left: 5px;
  }
  &__apply {
    background-image: url('../../images/svg/arrow-down-wide-short-solid.svg');
    width: 14px;
    height: 14px;
  }
  &__nestedSites {
    background-image: url('../../images/svg/icon_customerHierarchy_table_nestedSite.svg');
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }
  &__tooltip-grey {
    background-image: url('../../images/svg/icon_customerHierarchy_tooltip.svg');
    width: 12px;
    height: 12px;
  }
  &__table-label {
    background-image: url('../../images/svg/icon_customerHierarchy_table_label.svg');
    width: 14px;
    height: 14px;
  }
  &__remove-black {
    background-image: url('../../images/svg/icon_customerHierarchy_remove.svg');
    width: 12px;
    height: 14px;
  }
  &__warning {
    background-image: url('../../images/svg/icon_warning.svg');
    width: 24px;
    height: 16px;
  }
  &__createLabels {
    background-image: url('../../images/svg/icon_customerHierarchy_manageLabels.svg');
    width: 24px;
    height: 20px;
    margin: 10px 0px;
  }
  &__tableError {
    background-image: url('../../images/svg/icon_customerHierarchy_table_error.svg');
    width: 13px;
    height: 12px;
  }
  &__excel {
    background-image: url('../../images/svg/icon_excel.svg');
    width: 13px;
    height: 18px;
  }
  &__sortDesc {
    background-image: url('../../images/svg/icon_sort_descending.svg');
    width: 10px;
    height: 13px;
    margin-left: 10px;
  }
  &__docterSolid {
    background-image: url('../../images/svg/icon_user_doctor_solid.svg');
    width: 10px;
    height: 11px;
  }
  &__corp-parent-big {
    background-image: url('../../images/svg/corp_parent.jpg');
    width: 62px;
    height: 64px;
    margin-right: 0;
  }
  &__edit-icon {
    background-image: url('../../images/svg/icon_customerHierarchy_assignAttributes.svg');
    fill: #004677;
    width: 15px;
    height: 15px;
  }
  &__tick {
    background-image: url('../../images/svg/icon_customerHierarchy_table_340B.svg');
    width: 14px;
    height: 14px;
  }
  &__non-tick {
    background-image: url('../../images/svg/icon_customerHierarchy_table_non340B.svg');
    width: 14px;
    height: 14px;
  }
  &__whats-new-title {
    background-image: url('../../images/svg/icon_whatsNewTitle.svg');
    width: 21px;
    height: 21px;
  }
  &__whats-new-access-circle {
    background-image: url('../../images/svg/icon_access_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-announcement-circle {
    background-image: url('../../images/svg/icon_announcement_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-customer-hierarchy-circle {
    background-image: url('../../images/svg/icon_customerHierarchy_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-customer-view-circle {
    background-image: url('../../images/svg/icon_customerView_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-business-performance-circle {
    background-image: url('../../images/svg/icon_businessPerformance_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-contracting-circle {
    background-image: url('../../images/svg/icon_menu_contracting_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-market-landscape_circle {
    background-image: url('../../images/svg/icon_menu_marketLandscape_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-medical-circle {
    background-image: url('../../images/svg/icon_menu_medical-v1_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-payer-circle {
    background-image: url('../../images/svg/icon_menu_payer_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-engagement-circle {
    background-image: url('../../images/svg/icon_menu_engagement_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-other-tools-circle {
    background-image: url('../../images/svg/icon_menu_tools_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-home-circle {
    background-image: url('../../images/svg/icon_menu_home-circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-trends-circle {
    background-image: url('../../images/svg/icon_trendsTitle_white_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-configure-circle {
    background-image: url('../../images/svg/icon_whatsNew_configure_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-default-circle {
    background-image: url('../../images/svg/icon_whatsNew_default_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-documents-circle {
    background-image: url('../../images/svg/icon_whatsNew_documents_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-insight-boards-circle {
    background-image: url('../../images/svg/icon_whatsNew_insightBoards_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__whats-new-my-view-circle {
    background-image: url('../../images/svg/icon_whatsNew_myView_circle.svg');
    width: 28px;
    height: 28px;
  }
  &__site-big {
    background-image: url('../../images/svg/site.jpg');
    width: 62px;
    height: 64px;
    margin-right: 0;
  }
  &__oops {
    background-image: url('../../images/png/oops.png');
    width: 69px;
    height: 69px;
  }
  &__raise-request {
    background-image: url('../../images/svg/icon_raiseRequest.svg');
    width: 17.99px;
    height: 19.68px;
  }
  &__whats-new {
    background-image: url('../../images/svg/icon_whatsNew.svg');
    width: 19px;
    height: 19px;
  }
  &__logout {
    background-image: url('../../images/svg/icon_whatsNew_signOut.svg');
    width: 19px;
    height: 16.625px;
  }
  &__switchRoles {
    background-image: url('../../images/svg/icon_switchRoles.svg');
    width: 19px;
    height: 16.625px;
  }
  &__userGuide-blue {
    background-image: url('../../images/svg/icon_userGuideblue.svg');
    width: 18px;
    height: 18px;
  }
  &__leave-Feedback {
    background-image: url('../../images/svg/icon_leaveFeedback.svg');
    width: 18.9px;
    height: 19px;
  }

  &__howTo-Videos {
    background-image: url('../../images/svg/icon_howToVideos.svg');
    width: 18.68px;
    height: 18.68px;
  }

  &__notification-bell {
    background-image: url('../../images/svg/icon_notification_bell.svg');
    width: 14px;
    height: 16px;
    margin: 0;
  }
  &__notification-bell-black {
    background-image: url('../../images/svg/icon_notification_bell_black.svg');
    width: 14px;
    height: 16px;
    margin: 5;
  }
  &__primary-contact {
    background-image: url('../../images/svg/icon_primary_contact.svg');
    width: 28px;
    height: 28px;
  }
  &__priority_account {
    background-image: url('../../images/svg/icon_priority_account.svg');
    width: 28px;
    height: 28px;
  }
  &__insight-board {
    background-image: url('../../images/svg/icon_insight_board.svg');
    width: 28px;
    height: 28px;
  }
  &__veeva-link {
    background-image: url('../../images/svg/icon_veeva_v-link.svg');
    width: 33px;
    height: 11px;
    transform: scale(1) translate(0%, -50%);
  }
}
.navbar-expand {
  height: 24px;
  width: 24px;
  background-image: url('../../images/svg/icon_menu-expand.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
.navbar-collapse {
  height: 24px;
  width: 24px;
  background-image: url('../../images/svg/icon_menu-collapse.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.expand-table-row {
  height: 24px;
  width: 24px;
  background-image: url('../../images/png/icon_menu-collapse.png');
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1098px) {
  .gene-logo {
    width: 140px;
    height: 20px;
  }
}
