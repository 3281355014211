.favorites-container {
  top: 228px;
  left: 96px;
}

.stickyTab {
  position: sticky;
  top: 0px;
  z-index: 9;
  overflow: hidden;
  background: var(--color-white);
}
