.banner-wrapper {
  background: var(--color-secondary-blue);
  height: 360px;
  border-radius: var(--border-radius);
  color: var(--color-white);
  padding: 30px 63px;
  transition: all 1s ease-in-out;
}
.info-container {
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
}
.info-section {
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
  p {
    font-size: var(--font-size-x-small);
    margin-bottom: 10px;
    line-height: 100%;
    transition: all 0.5s ease-in-out;
    &.figures {
      padding-bottom: 8px;
      margin-top: -8px;
      display: flex;
      align-items: center;
      transition: all 0.5s ease-in-out;
    }
  }
  a {
    font-size: var(--font-size-x-small);
  }
}
.email-section {
  color: var(--color-white);
  text-decoration: underline;
  &:hover {
    color: var(--color-white);
  }
}
.contact-card {
  height: 70px;
  background: var(--color-light-blue);
  width: 33.33%;
  border-radius: var(--border-radius);
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  transition: all 0.5s ease-in-out;
  &__grey {
    background: var(--color-light-brown);
  }
}
.two-column {
  width: 100%;
  margin-bottom: 10px;
}
.contact-image {
  background-image: url('../../../assets/images/svg/icon_avatar.svg');
  border-radius: 50%;
  height: 28px;
  width: 28px;
  transition: all 0.5s ease-in-out;
  background-repeat: no-repeat;
  &__blue {
    background-image: url('../../../assets/images/svg/icon_contactAvatar.svg');
  }
}
.contact-image-banner {
  background-image: url('../../../assets/images/svg/icon_avatar.svg');
  border-radius: 50%;
  height: 28px;
  width: 8%;
  transition: all 0.5s ease-in-out;
  &__blue {
    background-image: url('../../../assets/images/svg/icon_contactAvatar.svg');
  }
}
.contact-data {
  width: 87%;
}
.contact-data-banner {
  width: 92%;
}
.contact-detail {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: 1rem;
  font-size: var(--font-size-x-small);
  word-break: break-all;
  line-height: 100%;
  transition: all 0.5s ease-in-out;
}

.role-code {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contact-detail-banner {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: 1rem;
  font-size: var(--font-size-x-small);
  word-break: break-all;
  line-height: 100%;
  transition: all 0.5s ease-in-out;
}
.link-cta {
  color: var(--color-light-blue-2);
  font-size: var(--font-size-small);
  font-family: var(--font-gene-sans-bold);
  transition: all 0.5s ease-in-out;
}
.chart-container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  height: 100%;
  transition: all 0.5s ease-in-out;
}
.radio_button {
  padding: auto;
  margin: 7px 10px 0px 10px;
  align-items: center;
}

.radio_label {
  padding: auto;
  margin: 0px 5px;
  align-items: center;
}

.radio_selection {
  padding: auto;
  margin: 0px 20px;
  position: relative;
  z-index: 9;
}

.text-height {
  height: 20px;
}

.text-disclamer {
  padding: 10px;
}
.header_style {
  padding-left: 10px;
  height: 45px;
  width: -webkit-fill-available;
}

.header_allign {
  position: relative;
  cursor: pointer;
  z-index: 10;
  top: -20px;
  height: 45px;
}

.switch_allign {
  position: relative;
  top: -15px;
  height: 15px;
  margin-left: 60px;
  z-index: 20;
}

.affaliated-to {
  text-decoration: underline;
  width: 200px;
  color: var(--color-light-blue-2);
}

.piecharttextAlign {
  margin: 0px 0px 40px 15px;
  font-size: 10px;
}
@media only screen and (max-width: 1194px) {
  .banner-wrapper {
    padding: 30px 20px;
  }
}
